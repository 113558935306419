import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const home = () => import('@/views/home/index.vue');

const aboutUs = () => import('@/views/aboutUs/index.vue')

const personnelAssignment = () => import('@/views/personnelAssignment/index.vue')

const softwareOutsourc = () => import('@/views/softwareOutsourc/index.vue')

const solution = () => import('@/views/solution/index.vue')

const routes = [
  {
    path: '',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: {
      title: '首页-福州市融智云科技有限公司'
    }

  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs,
    meta: {
      title: '关于我们-福州市融智云科技有限公司'
    }

  },
  {
    path: '/personnelAssignment',
    name: 'personnelAssignment',
    component: personnelAssignment,
    meta: {
      title: '人员驻点-福州市融智云科技有限公司'
    }

  },
  {
    path: '/softwareOutsourc',
    name: 'softwareOutsourc',
    component: softwareOutsourc,
    meta: {
      title: '软件外包-福州市融智云科技有限公司'
    }

  },
  {
    path: '/solution',
    name: 'solution',
    component: solution,
    meta: {
      title: '软件外包-福州市融智云科技有限公司'
    }

  },


]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
