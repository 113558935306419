<template>
  <div id="app">
    <!-- 导航栏 -->
      <router-view></router-view>
    <footerbar></footerbar>
  </div>
</template>

<script>

import footerbar from "./components/footerbar.vue";
export default {
  name: "app",
  components: { footerbar },
  data(){
    return{

    }
  },
  methods:{
 
  }
};
</script>

<style>
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
li,
ul,
ol,
span,
p,
a,
i {
  margin: 0;
  padding: 0;
  font-family: "微软雅黑";
}
.container {
  width: 100%;
  overflow: hidden;
  padding-top:80px ;
}
@media only screen and (max-width: 800px) {
  .title {
     margin: 16px 0 !important;
   margin-top: -64px !important;
  
  }
}
</style>
