<template>
  <!-- 底部432 -->
  <div class="footer">
    <el-row style="margin: 0; display: flex; flex-wrap: wrap">
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 24, offset: 0 }"
        :md="{ span: 24, offset: 0 }"
        :lg="{ span: 20, offset: 2 }"
        :xl="{ span: 16, offset: 4 }"
      >
        <div class="footer-main">
          <div class="info-box">
            <div class="info-tag">
              <div class="info-tag-t">构建产品解决方案领跑者</div>
              <div class="info-tag-e">ALBUM PAINTING</div>
            </div>
            <div class="itemRight">
              <div class="itemRightTop">
                <div class="info-fc info-item">Tel/联系电话：18960713881</div>
                <div class="info-fc info-item">E-mail：18960713881@189.cn</div>
              </div>
              <div class="info-fc info-item">
                Address/地址：福州高新区科技东路3号创新园一期6#楼5层
              </div>
            </div>
          </div>
        
          <div class="info-last">
            <div>© 2019-2022 福州市融智云科技有限公司©. All Right Reserved</div>
            <div style="cursor: pointer;" @click="getcheck" >闽公网安备 35010202001020号</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import hzhb1 from "@/assets/image/home/hzhb.png";
import hzhb2 from "@/assets/image/home/hzhb.png";
import hzhb3 from "@/assets/image/home/hzhb.png";
import hzhb4 from "@/assets/image/home/hzhb.png";
import hzhb5 from "@/assets/image/home/hzhb.png";
import hzhb6 from "@/assets/image/home/hzhb.png";
export default {
  data() {
    return {
      hzhbList: [hzhb1, hzhb2, hzhb3, hzhb4, hzhb5, hzhb6],
    };
  },
  methods:{
    
    getcheck(){
      location.href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35010202001020"
    }
  }
};
</script>

<style  scoped>
.footer {
  width: 100%;
  overflow: hidden;
  background-color: #2c2c2c;
  padding-bottom: 32px;
}

.hzhb {
  text-align: left;
  margin-top: 80px;
  font-size: 14px;
  color: #999;

}
.hzhbList {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  flex-wrap: wrap;
}

.hzhbList-item {
  width: 160px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
}
.hzhbList-itemImg {
  width: 100%;
  height: 100%;
}

.info-box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 32px;

}
.info-tag {
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.info-tag-t {
  font-size: 20px;
  line-height: 40px;
  color: #4087f3;
}
.info-tag-e {
  font-size: 18px;
  line-height: 30px;
  color: #999;
}

.info-item {
  margin: 16px;
  line-height: 30px;
}
.info-fc {
  font-size: 14px;
  color: #999;
}

.info-last {
  margin-top: 16px;
  font-size: 12px;
  color: #999999;
  text-align: center;
  line-height: 30px;
  border-top: 1px solid #dddddd27;
  padding-top: 16px;
}

.itemRight {
  display: flex;

  flex: 1;
  height: 100%;
  padding-left: 64px;
}

.itemRightTop {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 820px) {
  .info-box{
     flex-direction: column;
  }
  .itemRight {
       flex-direction: column;
       padding-left: 0;
  }
  .itemRightTop {
  display: flex;
      flex-direction: column;
  justify-content: center;
  align-items: center;
}
}
</style>